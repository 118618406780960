import React, { useState } from "react";
import styles from "./index.module.scss";
import LaunchIconYellow from "assets/images/icons/launch-icon-yellow.png";
import LaunchIconWhite from "assets/images/icons/launch-icon-white.png";

const Button = ({ type = "button", text = "", variant = "", clickHandler }) => {
  const [isHovered, setIsHovered] = useState(false);

  const toggleHover = (STATE) => {
    setIsHovered(STATE);
  };

  return (
    <button
      type={type}
      className={`${styles.button} ${styles[variant]}`}
      onClick={clickHandler && clickHandler}
      onMouseEnter={() => toggleHover(true)}
      onMouseLeave={() => toggleHover(false)}
    >
      <span className={styles.button__icon}>
        <img src={isHovered ? LaunchIconWhite : LaunchIconYellow} alt="Icon" />
      </span>

      <span className={styles.button__text}>{text && text}</span>
    </button>
  );
};

export default Button;
