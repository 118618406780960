import React, { createContext, useState } from 'react';

const ImageRotationContext = createContext();

const ImageRotationProvider = ({ children }) => {
  const [rotateImage, setRotateImage] = useState(false);

  const handleRotateImage = () => {
    setRotateImage(!rotateImage);
  };

  return (
    <ImageRotationContext.Provider value={{ rotateImage, handleRotateImage }}>
      {children}
    </ImageRotationContext.Provider>
  );
};

export { ImageRotationContext, ImageRotationProvider };
