import React, { useState, useEffect } from "react";
import styles from "./index.module.scss";

const TeamCard = ({ name, specializations, linkedin, avatar }) => {
  const [images, setImages] = useState({
    avatar: "",
    // eslint-disable-next-line no-undef
    linkedin: require(`../../assets/images/team/linkedin-icon-blue.png`),
  });

  useEffect(() => {
    // eslint-disable-next-line no-undef
    const myAvatar = require(`../../${avatar}`);

    setImages({
      ...images,
      avatar: myAvatar,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.card}>
      <div className={styles.card__wrapper}>
        <div className={styles.card__left}>
          <div className={styles.card__avatar}>
            <figure>
              <img src={images.avatar} alt={name} />
            </figure>
          </div>
        </div>
        <div className={styles.card__right}>
          <div className={styles.card__name}>
            <h2 title={name}>{name}</h2>
          </div>

          <div className={styles.card__info}>
            <div className={styles.card__text}>
              {specializations &&
                specializations.length &&
                specializations.map((speciality, index) => {
                  return <p key={index}>{speciality}</p>;
                })}
            </div>

            <a
              href={linkedin}
              target="_blank"
              rel="noreferrer"
              className={styles.card__linkedin}
            >
              <figure>
                {/* eslint-disable-next-line no-undef */}
                <img src={images.linkedin} alt={`${name}'s Linkedin`} />
              </figure>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamCard;
