import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
import { roadmapConfig } from "configs/roadmapConfig";
import Loader from "components/Loader";
import { useTranslation } from "react-i18next";

const RoadMap = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, [2500]);
  }, []);

  const getIsActive = (index) => {
    const today = new Date();
    const month = (today.getMonth() + 1).toString().padStart(2, "0");
    const year = today.getFullYear();
    const endDay = roadmapConfig[index].endDate.getDate();

    if (
      roadmapConfig[index].startDate.getTime() >=
        new Date(`${year}-${month}-01`).getTime() &&
      roadmapConfig[index].endDate.getTime() <=
        new Date(`${year}-${month}-${endDay}`).getTime()
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className="container">
      <Loader isLoading={isLoading} />

      <div className={styles.roadMap}>
        <section className={styles.roadMap__section}>
          <div className={styles.roadMap__top}>
            <h1 className="heading-2 center">{t("Roadmap")}</h1>

            <div className={styles.roadMap__layout}>
              {roadmapConfig?.length &&
                roadmapConfig.map((item, index) => {
                  return (
                    <div
                      key={index}
                      id={`${getIsActive(index) ? "activeRoadmapCard" : ""}`}
                      className={`${styles.card} ${
                        getIsActive(index) ? styles.active : ""
                      }`}
                    >
                      <div
                        className={`${styles.card__title} ${
                          index !== roadmapConfig.length - 1
                            ? styles.notLast
                            : ""
                        }`}
                      >
                        {t(item.name)}

                        <span className={styles.horizontalLine}></span>
                      </div>

                      <div
                        className={`${styles.card__text} ${
                          index !== roadmapConfig.length - 1
                            ? styles.notLast
                            : ""
                        }`}
                      >
                        {t(item.text)}
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default RoadMap;
