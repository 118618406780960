import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
import Loader from "components/Loader";
import HeroImage from "assets/images/gp-academy/gp-academy.png";

const Academy = () => {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, [2500]);
  }, []);

  return (
    <div className="container">
      <Loader isLoading={isLoading} />

      <div className={styles.gpAcademy}>
        <div className={styles.gpAcademy__heroSection}>
          <div className={styles.heroSection}>
            <div className={styles.heroSection__top}>
              <div className={styles.heroSection__heading}>
                <h2>GoldPesa Academy</h2>
              </div>
            </div>

            <div className={styles.heroSection__bottom}>
              <div className={styles.heroSection__image__wrapper}>
                <figure className={styles.heroSection__image}>
                  <img src={HeroImage} alt="GP STAKE2WIN" />
                </figure>
              </div>
            </div>

            <div className={styles.heroSection__text}>
              <p>
                Learn about crypto and blockchain technology from actual
                engineers, scientists and coders.
              </p>
            </div>
          </div>
        </div>

        <div className={styles.gpAcademy__mission}>
          <div className={styles.gpAcademy__mission__heading}>
            <h2>OUR MISSION</h2>
          </div>

          <div className={styles.gpAcademy__mission__text}>
            <p>Helping our community navigate the world of blockchain.</p>
            <p>
              To provide students with the necessary tools to assess
              opportunities in the crypto space.
            </p>
            <p>
              To help you understand how to protect yourself from inherent risks
              in the crypto market.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Academy;
