import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
import Loader from "components/Loader";
import HeroImage from "assets/images/goldmines/gp-mines.png";
import GPMountainsDesktop from "assets/images/goldmines/gp-mountains-1.png";

const Goldmines = () => {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, [2500]);
  }, []);

  return (
    <div className="container">
      <Loader isLoading={isLoading} />

      <div className={styles.goldmines}>
        <div className={styles.goldmines__heroSection}>
          <div className={styles.heroSection}>
            <div className={styles.heroSection__top}>
              <div className={styles.heroSection__heading}>
                <h2>GoldPesa Mines</h2>
              </div>
            </div>

            <div className={styles.heroSection__bottom}>
              <div className={styles.heroSection__image__wrapper}>
                <figure className={styles.heroSection__image}>
                  <img src={HeroImage} alt="Goldmines" />
                </figure>
              </div>
            </div>

            <div className={styles.heroSection__text}>
              <p>
                GoldPesa will be launching a unique play to earn game, whereby
                players compete with other players for GPO tokens while they
                advance deeper into the GoldPesa Mine.
              </p>
              <p>
                Players will be required to dig using GPO in order to mine real
                GPX tokens, which effectively simplifies the process of
                exercising GoldPesa Options (GPO) to purchase GoldPesa Tokens
                (GPX).
              </p>
              <p>
                A truly innovative gamification of DeFi and real-world assets.
              </p>
            </div>
          </div>
        </div>

        <div className={styles.goldmines__mountains}>
          <figure>
            <img src={GPMountainsDesktop} alt="GP Mountains" />
          </figure>
        </div>
      </div>
    </div>
  );
};

export default Goldmines;
