import React, { useEffect, useState, Fragment } from "react";
import styles from "./index.module.scss";
import { ReactComponent as ArrowUpLong } from "assets/images/icons/arrow-up-long.svg";

const ScrollToTopButton = () => {
  const [showButton, setShowButton] = useState(false);

  const moveToTop = () => {
    window.scrollTo(0, 0);
  };

  const showHideButton = () => {
    if (window.scrollY >= 250) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", showHideButton);
  }, []);

  return (
    <Fragment>
      {showButton && (
        <button
          type="button"
          onClick={moveToTop}
          className={styles.scrollToTopButton}
        >
          <i>
            <ArrowUpLong />
          </i>
        </button>
      )}
    </Fragment>
  );
};

export default ScrollToTopButton;
