import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
import Loader from "components/Loader";
import HeroImage from "assets/images/stake-2-win/stake-2-win-logo.png";

const StakeTwoWin = () => {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, [2500]);
  }, []);

  return (
    <div className="container">
      <Loader isLoading={isLoading} />

      <div className={styles.stakeTwoWin}>
        <div className={styles.stakeTwoWin__heroSection}>
          <div className={styles.heroSection}>
            <div className={styles.heroSection__top}>
              <div className={styles.heroSection__heading}>
                <h2>Stake2Win</h2>
              </div>
            </div>

            <div className={styles.heroSection__bottom}>
              <div className={styles.heroSection__image__wrapper}>
                <figure className={styles.heroSection__image}>
                  <img src={HeroImage} alt="STAKE2WIN" />
                </figure>
              </div>
            </div>

            <div className={styles.heroSection__text}>
              <p>
                A unique no-loss lottery model where users have a chance to win
                a lump sum of tokens without any chance of loss! Yes, you heard
                that right!
              </p>
              <p>
                In this model, users stake GPO, GPX or USDC tokens into a pool
                which is deployed into the GoldPesa Liquidity Protocol. One
                lucky winner takes all the accrued interest as a lottery prize
                while the rest of players receive their original tokens back.
                Lotteries are transparent & on-chain, and are conducted over a
                period of time.
              </p>
              <p>The more players, the bigger the prize!</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StakeTwoWin;
