import "./App.scss";
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { AppContext } from "context/AppContext";
import AnimatedCursor from "react-animated-cursor";
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import { I18nextProvider } from 'react-i18next';
import Layout from "layouts/Layout";
import Home from "pages/Home";
import MediaAndPress from "pages/MediaAndPress";
import Team from "pages/Team";
import RoadMap from "pages/Roadmap";
import FAQ from "pages/FAQ";
import AboutUs from "pages/AboutUs";
// import Xenii2 from "pages/Xenii2";
// import GoldMines from "pages/GoldMines";
// import Stake2Win from "pages/Stake2Win";
// import Academy from "pages/Academy";
import ScrollToTop from "Hooks/scrollToTop";
import ScrollToTopButton from "components/ScrollToTopButton";
// import ReactCountryFlag from "react-country-flag";
// import  { useState } from "react";
import i18n from "i18next";
import en from "./locales/en.json";
import fa from "./locales/fa.json";3
import { QueryClient, QueryClientProvider } from 'react-query'

const queryClient = new QueryClient()


import { ImageRotationProvider } from '../src/ImageRotationContext';

const client = new ApolloClient({
  uri: 'https://api.thegraph.com/subgraphs/name/ianlapham/uniswap-v3-polygon',
  cache: new InMemoryCache(),
});

i18n.init({
  lng: "en",
  fallbackLng: "en",
  resources: {
    en: { translation: en },
    fa: { translation: fa },
  },
});

const App = () => {

  return (
    <ImageRotationProvider>
    <AppContext.Provider value={{}}>
     <QueryClientProvider client={queryClient}>
      <ApolloProvider client={client}>
        <Router>
          <AnimatedCursor
            color="255,255,255"
            innerSize={0}
            outerSize={15}
            innerScale={0}
            outerScale={2}
            outerAlpha={0}
            outerStyle={{
              backgroundColor: "rgba(255, 255, 255, 0.5)",
            }}
            hideNativeCursor={false}
            clickables={[
              "a",
              'input[type="text"]',
              'input[type="email"]',
              'input[type="number"]',
              'input[type="submit"]',
              'input[type="image"]',
              "label[for]",
              "select",
              "textarea",
              "button",
              ".myLinkForCursor",
            ]}
          />
          <I18nextProvider i18n={i18n}>
            <Layout>
              <ScrollToTop />
              <ScrollToTopButton />

            <Routes>
              <Route path="/" exact element={<Home />} />
              <Route path="/media-and-press" element={<MediaAndPress />} />
              <Route path="/team" element={<Team />} />
              <Route path="/roadmap" element={<RoadMap />} />
              <Route path="/faq" element={<FAQ />} />
              <Route path="/about-us" element={<AboutUs />} />
              {/* <Route path="/xenii" element={<Xenii2 />} />
              <Route path="/mines" element={<GoldMines />} />
              <Route path="/stake-2-win" element={<Stake2Win />} />
              <Route path="/academy" element={<Academy />} /> */}
            </Routes>
          </Layout>
          </I18nextProvider>
        </Router>
      </ApolloProvider>
     </QueryClientProvider>
    </AppContext.Provider>
    </ImageRotationProvider>
  );
};

export default App;
