/* eslint-disable no-undef */
import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";

import { mediaAndPressConfig } from "configs/mediaAndPressConfig";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";

// import required modules
import { Autoplay } from "swiper";

const InfiniteLoopBrandSlider = () => {
  const [images, setImages] = useState([]);
  useEffect(() => {
    const myArray = [];

    for (let i = 1; i <= mediaAndPressConfig.length; i++) {
      // eslint-disable-next-line no-undef
      myArray.push({
        logo: require(`assets/images/media-logo/logo-${i}.png`),
        thumbnail: require(`assets/images/media-thumbnail/thumbnail-${i}.png`),
      });
    }

    setImages(myArray);
  }, []);

  return (
    <div className={styles.mediaCardsSlider}>
      <Swiper
        loop={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        dir="ltr"
        modules={[Autoplay]}
        // Responsive breakpoints
        breakpoints={{
          // when window width is >= 320px
          320: {
            spaceBetween: 35,
            slidesPerView: 1,
          },
          // when window width is >= 768px
          768: {
            slidesPerView: 2,
            spaceBetween: 35,
          },
          // when window width is >= 1280px
          1280: {
            spaceBetween: 100,
          },
          // when window width is >= 1440px
          1440: {
            slidesPerView: 2,
            spaceBetween: 160,
          },
          // when window width is >= 1600px
          1600: {
            slidesPerView: 3,
            spaceBetween:100,
          },
        }}
        className={styles.mySwiper}
      >
        {mediaAndPressConfig.length &&
          mediaAndPressConfig.map((mediaItem, index) => {
            return (
              <SwiperSlide key={index} className={styles.mySwiperSlide}>
                <div className={styles.card}>
                  <div className={styles.card__wrapper}>
                    <div className={styles.card__logo}>
                      <div className={styles.card__logo__wrapper}>
                        <figure>
                          <img src={images[index]?.logo} alt="" />
                        </figure>
                      </div>
                    </div>
                    <div className={styles.card__date}>
                      <p>{mediaItem.date}</p>
                    </div>
                    <div className={styles.card__title}>
                      <a href={mediaItem.link} target="_blank" rel="noreferrer" title={mediaItem.title}>
                        {mediaItem.title}
                      </a>
                    </div>
                    <a href={mediaItem.link} target="_blank" rel="noreferrer" className={styles.card__image}>
                      <figure>
                        <img src={images[index]?.thumbnail} alt="" />
                      </figure>
                    </a>
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
      </Swiper>
    </div>
  );
};

export default InfiniteLoopBrandSlider;
