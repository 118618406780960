import React, { useState, useEffect, useRef, Fragment, useContext } from "react";
import styles from "./index.module.scss";
// import { FormControlLabel, Switch } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { customCloneDeep, debounce } from "utils/utilityFunctions";
import { initialHeaderConfig, launchAppLink } from "configs/headerConfigs";
import { useTranslation } from "react-i18next";
import Button from "components/Button";
/* Logo & SVG Icons Below */
import Logo from "assets/images/goldpesa-logo-6.png";
import { ReactComponent as ChevronDownIcon } from "assets/images/icons/chevron-down.svg";
import { ReactComponent as TriangleIcon } from "assets/images/icons/triangle.svg";
import { ReactComponent as ExternalLinkIcon } from "assets/images/icons/external-link.svg";
import { ReactComponent as CloseIcon } from "assets/images/icons/close.svg";
import { ReactComponent as HamburgerIcon } from "assets/images/icons/hamburger.svg";
// import ReactCountryFlag from "react-country-flag";
import ReactFlagsSelect from "react-flags-select";
import i18n from "i18next";
import en from "../../locales/en.json";
import fa from "../../locales/fa.json";

import { ImageRotationContext  } from '../../../src/ImageRotationContext';

i18n.init({
  lng: 'en',
  fallbackLng: 'en',
  resources: {
    en: { translation: en },
    fa: { translation: fa },
  },
});

const LanguageSelector = ({ selectedLanguage, onChangeLanguage }) => {
  const { handleRotateImage } = useContext(ImageRotationContext);
  const { t } = useTranslation();

  const languageOptions = [
    { code: 'en', label: t('English'), countryCode: 'US' },
    // { code: 'fa', label: t('Persian'), countryCode: 'IR' },
  ];


  const handleLanguageChange = (countryCode) => {
    const selectedOption = languageOptions.find((option) => option.countryCode === countryCode);
    if (selectedOption) {
      onChangeLanguage(selectedOption.code);
      handleRotateImage();
      const htmlTag = document.getElementsByTagName("html")[0];
      htmlTag.setAttribute("dir", selectedOption.code === 'fa' ? "rtl" : "ltr");
    }
  };

  const renderPlaceholder = () => {
    const selectedOption = languageOptions.find((option) => option.code === selectedLanguage);
    if (selectedOption) {
      return selectedOption.label;
    }
    return null;
  };

  return (
    <div>
  <div style={{ backgroundColor: 'transparent', color: 'black', border: '1px solid #f0ce0e', borderRadius: '30px', height: '38px', width: '108px' }}>
    <ReactFlagsSelect
      onChange={handleLanguageChange}
      selected={selectedLanguage}
      onSelect={handleLanguageChange}
      countries={languageOptions.map((option) => option.countryCode)}
      customLabels={languageOptions.reduce((labels, option) => {
        labels[option.countryCode] = option.label;
        return labels;
      }, {})}
      
      placeholder={renderPlaceholder()}
      searchable={false}
      alignOptionsToRight={selectedLanguage === 'fa'}
      selectedSize={16} // Set the desired font size for the selected country label
      selectedSizeUnit="px" // Specify the unit of the font size (e.g., pixels)
    />
  </div>
</div>
);
};


const Header = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [isHeaderFixed, setIsHeaderFixed] = useState(false);
  const { t } = useTranslation();
  const headerRef = useRef();
  const location = useLocation();
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
  const [headerConfig, setHeaderConfig] = useState(initialHeaderConfig);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isPageScrolled, setIsPageScrolled] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isRTL, setIsRTL] = useState(selectedLanguage === 'fa');
  const [isLanguageChanging, setIsLanguageChanging] = useState(false);
  const [marginRight, setMarginRight] = useState(0);


  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1600) {
        setMarginRight(20);
      } else {
        setMarginRight(0);
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 820);
    };

    const handleScroll = () => {
      setIsHeaderFixed(window.scrollY > 0);
    };

    handleResize(); 

    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const changeLanguage = (lng) => {
    setIsLanguageChanging(true); 
  
    i18n.changeLanguage(lng, (err) => {
      if (err) {
        console.log('Something went wrong while changing the language.', err);
      } else {
        setSelectedLanguage(lng);
        setIsRTL(lng === 'fa');
  
        setTimeout(() => {
          setIsLanguageChanging(false); 
        }, 500); 
      }
    });
  };


  const launchAppFunc = () => {
    window.location.href = launchAppLink;
  };

  const toggleActiveOption = (index, state) => {
    const headerConfigClone = customCloneDeep(headerConfig);

    headerConfigClone.forEach((item, i) => {
      if (index === i) {
        if (item.isActive !== state) item.isActive = state;
      }
    });

    setHeaderConfig(headerConfigClone);
  };

  const toggleDrawer = () => {
    setIsDrawerOpen((prevState) => !prevState);
  };

  
  const changeHeaderBackground = () => {
    if (window.scrollY >= headerRef.current?.clientHeight / 2) {
      setIsPageScrolled(true);
    } else {
      setIsPageScrolled(false);
    }
  };

  const toggleModal = (state) => {
    setIsModalOpen(state);
  };

  useEffect(() => {
    if (isDrawerOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isDrawerOpen]);

  useEffect(() => {
    window.addEventListener("scroll", changeHeaderBackground);
  
    return () => {
      window.removeEventListener("scroll", changeHeaderBackground);
    };
  }, []);

  useEffect(() => {
    if (location.search && location.search.includes("how-to-buy")) {
      if (window.scrollY !== 0) {
        window.scrollTo(0, 0);
  
        setTimeout(() => {
          toggleModal(true);
        }, 1000);
      } else {
        toggleModal(true);
      }
    }
  }, [location]);
  

  useEffect(() => {
    const body = document.getElementsByTagName("body")[0];
  
    if (isModalOpen) {
      body.style.overflow = "hidden";
    } else {
      body.style.overflow = "auto";
    }
  }, [isModalOpen]);

  return (
    <div
      ref={headerRef}
      id="header"
      className={`${styles.header} ${isPageScrolled ? styles.dark : ""}`}
    >
      <div className={styles.header__wrapper}>
        {/* Mobile Nagigation */}
        <div className={styles.mobileNavigation}>
          {/* Mobile Nagigation Button On Header */}
          <button
            type="button"
            className={`
            ${styles.mobileNavigation__button}
            ${isDrawerOpen ? styles.active : ""}
            `}
            onClick={toggleDrawer}
          >
            {isDrawerOpen ? <CloseIcon /> : <HamburgerIcon />}
          </button>

          {/* Mobile Nagigation Overlay */}
          <div
            className={styles.mobileNavigation__overlay}
            style={{ display: isDrawerOpen ? "block" : "none" }}
            onClick={toggleDrawer}
          ></div>

          {/* Mobile Nagigation Drawer */}
          <div
    className={`
      ${styles.mobileNavigation__drawer}
      ${isDrawerOpen ? styles.open : styles.close}
    `}
    style={{
      transform: isDrawerOpen ? 'translateX(0)' : isRTL ? 'translateX(120%)' : 'translateX(-100%)',
      right: !isRTL && isDrawerOpen ? 'unset' : 0,
      left: isRTL && isDrawerOpen ? 'unset' : 0,
      maxWidth: '65%',
      transition: isLanguageChanging ? 'none' : 'transform 0.3s ease-in-out', 
    }}
  >
            {/* Mobile Nagigation Top (Header) */}
            <div className={styles.mobileNavigation__top}>
              {/* Mobile Nagigation Button Inside Drawer */}
              <button
                type="button"
                className={`
                  ${styles.mobileNavigation__button}
                  ${isDrawerOpen ? styles.active : ""}
                `}
                onClick={toggleDrawer}
              >
                {isDrawerOpen ? <CloseIcon /> : <HamburgerIcon />}
              </button>

              {/* Mobile Nagigation Inside Logo */}
              <Link
                to="/"
                className={styles.header__logo}
                onClick={toggleDrawer}
              >
                <figure>
                  <img src={Logo} alt="Goldpesa Logo" />
                </figure>
              </Link>
            </div>

            {/* Mobile Nagigation Drawer Links List */}
            <div className={styles.mobileNavigation__links}>
              <ul className={styles.mobileNavigation__links__list}>
                {headerConfig?.length &&
                  headerConfig.map((item, index) => {
                    return (
                      <li
                        key={index}
                        className={styles.mobileNavigation__links__item}
                      >
                        {item.type === "link" ? (
                          <Link
                            to={item.link}
                            className={styles.mobileNavigation__links__link}
                            onClick={toggleDrawer}
                          >
                            {t(item.name)}
                          </Link>
                        ) : (
                          <Fragment>
                            <div
                              className={
                                styles.mobileNavigation__links__wrapper
                              }
                              onClick={() =>
                                toggleActiveOption(index, !item.isActive)
                              }
                            >
                              <span
                                className={styles.mobileNavigation__links__link}
                              >
                                {t(item.name)}
                              </span>

                              {item.type === "dropdown" && (
                                <span
                                  className={`${
                                    styles.mobileNavigation__links__icon
                                  } ${
                                    item.isActive
                                      ? styles[
                                          `mobileNavigation__links__icon--rotate`
                                        ]
                                      : ""
                                  }`}
                                >
                                  <ChevronDownIcon />
                                </span>
                              )}
                            </div>

                            {item?.dropdownLinks?.length && (
                              <ul
                                className={`${
                                  styles.mobileNavigation__links__innerList
                                } ${item.isActive ? styles.active : ""}`}
                              >
                                {item.dropdownLinks.map(
                                  (innerLinks, innerIndex) => {
                                    return (
                                      <li
                                        key={`${innerLinks.name}-${innerIndex}`}
                                        className={
                                          styles.mobileNavigation__links__innerItem
                                        }
                                        onClick={() =>
                                          toggleActiveOption(index, false)
                                        }
                                      >
                                        {innerLinks.isExternal ? (
                                          <a
                                            href={innerLinks.path}
                                            target="_blank"
                                            rel="noreferrer"
                                            className={
                                              styles.mobileNavigation__links__innerLink
                                            }
                                            onClick={toggleDrawer}
                                          >
                                            {t(innerLinks.name)}
                                          </a>
                                        ) : (
                                          <Link
                                            to={innerLinks.path}
                                            className={
                                              styles.mobileNavigation__links__innerLink
                                            }
                                            onClick={toggleDrawer}
                                          >
                                            {t(innerLinks.name)}
                                          </Link>
                                        )}
                                      </li>
                                    );
                                  }
                                )}
                              </ul>
                            )}
                          </Fragment>
                        )}
                      </li>
                    );
                  })}
              </ul>
            </div>

            {/* Mobile Nagigation CTA Button  */}
            <div className={styles.mobileNavigation__cta}>
              <Button
                type="button"
                text={t("LAUNCH APP")}
                clickHandler={launchAppFunc}
              />
            </div>
          </div>
        </div>

        {/* Header Logo  */}
        <Link
        
          to="/"
          className={`${styles.header__logo} ${styles["header__logo--desktop"]}`} 
        >
          <figure>
            <img src={Logo} alt="Goldpesa Logo" />
          </figure>
        </Link>

        {/* Header Desktop Navigation  */}
        <nav className={styles.header__navigation}>
          <ul className={styles.navigationList}>
            {headerConfig?.length &&
              headerConfig.map((item, index) => {
                return (
                  <li className={styles.navigationList__item} key={index}>
                    <div
                      className={styles.navigationList__item__wrapper}
                      onMouseEnter={() =>
                        debounce(toggleActiveOption(index, true))
                      }
                      onMouseLeave={() =>
                        debounce(toggleActiveOption(index, false))
                      }
                    >
                      <div
                        className={`${styles.navigationList__item__top} myLinkForCursor`}
                      >
                        <span className={styles.navigationList__item__name}>
                          {item.type === "link" ? (
                            <Link to={item.link}>{t(item.name)}</Link>
                          ) : (
                            <p>{t(item.name)}</p>
                          )}
                        </span>

                        {item.type === "dropdown" && (
                          <span
                            className={`${styles.navigationList__item__icon} ${
                              item.isActive
                                ? styles[`navigationList__item__icon--rotate`]
                                : ""
                            }`}
                          >
                            <ChevronDownIcon />
                          </span>
                        )}
                      </div>

                      {item.type === "dropdown" && (
                        <div
                          className={`${styles.navigationList__dropdown}  ${
                            item.isActive ? styles.show : styles.hide
                          }`}
                        >
                          <div
                            className={styles.navigationList__dropdown__wrapper}
                          >
                            <ul
                              className={styles.navigationList__dropdown__list}
                            >
                              {item.dropdownLinks &&
                                item.dropdownLinks.map((link, index) => {
                                  return (
                                    <li
                                      key={`${link.name}-${index}`}
                                      className={
                                        styles.navigationList__dropdown__item
                                      }
                                      onClick={() =>
                                        debounce(
                                          toggleActiveOption(index, false)
                                        )
                                      }
                                    >
                                      {link.isExternal ? (
                                        <a
                                          href={link.path}
                                          target="_blank"
                                          rel="noreferrer"
                                          className={
                                            styles.navigationList__dropdown__link
                                          }
                                        >
                                          {t(link.name)}
                                        </a>
                                      ) : (
                                        <Link
                                          to={link.path}
                                          className={
                                            styles.navigationList__dropdown__link
                                          }
                                        >
                                          {t(link.name)}
                                        </Link>
                                      )}

                                      <span
                                        className={
                                          styles.navigationList__dropdown__icon
                                        }
                                      >
                                        {link.isExternal ? (
                                          <ExternalLinkIcon />
                                        ) : (
                                          <TriangleIcon />
                                        )}
                                      </span>
                                    </li>
                                  );
                                })}
                            </ul>
                          </div>
                        </div>
                      )}
                    </div>
                  </li>
                );
              })}
          </ul>
        </nav>

        {/* Header Desktop CTA  */}
        <div className={styles.header__cta} style={{ marginLeft: "0px" }}>
          <Button
            type="button"
            text={t("LAUNCH APP")}
            clickHandler={launchAppFunc}
          />
        </div>



        <div className={`header ${isHeaderFixed ? 'fixed' : ''}`}>
      {isMobile && !isHeaderFixed && (
        <div className="language-switcher mobile-only">
          <LanguageSelector
            selectedLanguage={selectedLanguage}
            onChangeLanguage={changeLanguage}
          />
        </div>
      )}
      {!isMobile && (
        <div
        className="language-switcher desktop-only"
        style={{ marginRight: `${marginRight}px`}}
      >
        <div className="flag-container">
          <LanguageSelector
          style={{color: 'red'}}
            selectedLanguage={selectedLanguage}
            onChangeLanguage={changeLanguage}
          />
        </div>
      </div>
      )}
      <div className="other-header-content">
        {/* Other header content */}
      </div>
    </div>


      </div>

      {isModalOpen && (
        <div className={styles.modal}>
          <div
            className={styles.modal__backdrop}
            onClick={() => toggleModal(false)}
          ></div>
          <div className={styles.modal__container}>
            <div className={styles.modal__video}>
              <div className={styles.modal__video__wrapper}>
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube-nocookie.com/embed/iOTM6aBraZs"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;
