export const faqConfig = [
  {
    question: "What is GoldPesa (GPX)?",
    answer: [
      "GoldPesa (GPX) is an advanced form of money. GPX gives holders the opportunity to not only save money and hedge against inflation, but to earn a premium on their gold holdings.",
      "GoldPesa is able to offer investors this unique digital asset by using innovative financial engineering and quantitative science. Token holders can own fractional shares of physical gold whilst profiting from earnings generated by the PAWN, our intelligent trading algorithm.",
    ],
  },
  {
    question: "How does the GPO and GPX value proposition work?",
    answer: [
      "GoldPesa transactions involve 3 main components, GPX, GPO and the Pawn.",

      "1) Every GPX token is backed by 1 gram of physical gold. Each GPO token represents an option to purchase 1 newly minted GPX token at spot gold price + 1%.",

      "2) There is a 10% fee anytime a GPO is bought or sold but not when transferred. 5% is allocated to the PAWN, our proprietary intelligent trading algorithm and 5% into the liquidity pool.",

      "3) There is a 1% fee anytime GPX is bought, sold or transferred. 0.5% is allocated to the PAWN, our proprietary intelligent trading algorithm.",

      "4) The Pawn generates a profit by leveraging the capital and automatically placing trades in the forex market.",

      "5) The Pawn’s performance is broadcasted in real-time which not only creates transparency but also volatility among speculators in anticipation of the buyback.",

      "6) The volatility creates exponentially growing fees which results in an exponentially growing capital base for the PAWN.",

      "7) Profits generated by the PAWN are used to buy back and burn GPX tokens, which rewards token holders by driving the price higher.",

      "This cycle continues creating tremendous value for token holders. As a result, the price of GPX floats away from the spot price of gold and trades at a premium. The PAWB buyback and burn function effectively creates earnings for token holders.",

      "An asset that generates earnings has the potential to trade at a multiple of its earnings based on a P/E ratio which we traditionally see in the stock market.",

      "Using simple maths, when you take the GPX price and subtract the gold spot price, you get the theoretical intrinsic value of GPO.",

      "Say for example GPX was trading at $110 USD and the spot gold price for 1 gram is $50 USD. Then the theoretical intrinsic value of GPO would be $110 minus $50 which equals $60 USD.",

      "GPO is therefore an exotic call option which has the potential of a 100X meme token but is built on the backbone of intrinsic value rather than sheer speculation.",

      "Token holders can choose to exercise their option or simply hold their GPO like any other cryptocurrency.",

      "As a derivative of GPX, GPO will trade independently with a theoretical intrinsic value correlated to the premium of GPX.",
    ],
  },
  {
    question: "Who can buy GoldPesa (GPX)?",
    answer: [
      "Initially, GPX tokens will only be available to GoldPesa Option holders who wish to exercise their options.",

      "Once minted, GPX tokens will be available on the GoldPesa decentralised exchange powered by Uniswap V3.",
    ],
  },
  {
    question: "How can GPX trade at a premium?",
    answer: [
      "GoldPesa invests 50% of all fees in a brokerage account managed by the PAWN, a proprietary and highly intelligent trading algorithm.",

      "Profits generated by the PAWN are used to buy back and then burn GPX tokens from the market, which rewards token holders by creating demand and reducing supply at the same time.",

      "This model effectively creates earnings on an asset class (gold) that generally does not have any earnings and as a result we can expect the market to trade GPX at some multiple of the earnings (P/E ratio) which results in GPX being traded at a premium depending on the performance of the Pawn, our proprietary and highly intelligent trading algorithm.",

      "It's important to note that GPX token holder's gold remains unencumbered at all times as we risk our own fees/capital for the benefit of token holders, resulting in a token which has the characteristics of a DeFi asset-backed structured product.",

      "As GPX begins to trade at a premium, the intrinsic value of the GoldPesa Option token (GPO) increases as it is theoretically correlated to the premium of GPX.",
    ],
  },
  {
    question: "How do I buy GPX tokens?",
    answer: [
      "GPX tokens will be available for sale on our decentralised exchange <a href='https://app.goldpesa.com/'>app.goldpesa.com</a> powered by Uniswap.",

      "GPX tokens will be deployed and available on the Polygon blockchain.",
    ],
  },
  {
    question: "How much gold is backing the total supply of GoldPesa (GPX)?",
    answer: [
      "The amount of gold representing the total supply of GPX is a 1:1 ratio with one gram of physical gold backing each GPX token.",

      "The total supply of gold representing GPX changes continuously as the market capitalisation of GPX increases and decreases with a hard cap of 100,000,000 tokens.",
    ],
  },
  {
    question: "What is the minimum GPX I can buy?",
    answer: [
      "There is no minimum, GPX tokens are capable of being fractionalised up to 18 decimal places.",
    ],
  },
  {
    question: "What fees are associated with GPX?",
    answer: [
      "Anytime GPX is bought, sold or transferred, there is a fee of 1%.",
    ],
  },
  {
    question: "What happens if the PAWN loses all the money in the GPX fund?",
    answer: [
      "In the highly unlikely event that the PAWN, our proprietary trading algorithm, loses all the money in the GPX fund, token holders will simply not benefit from a buy back for that given period.",

      "As we are risking our own capital for the benefit of token holders, a catastrophic loss will not affect GPX token holders as the gold backing each token remains unencumbered at all times.",
    ],
  },
  {
    question: "Can I unwrap my GPX tokens and convert back to physical gold?",
    answer: [
      "GPX token holders have the ability to convert their tokens back to physical gold on a 1:1 basis at any time in the future.",
    ],
  },
  {
    question: "What is the storage and custody fee?",
    answer: [
      "GoldPesa does not charge token holders any storage or custody fees.",
    ],
  },
  {
    question: "What does GoldPesa do with GPO and GPX fees?",
    answer: [
      "GPO - 10% fees are charged when transacting GPO, of which 50% is allocated to the PAWN, our intelligent trading algorithm and the remaining 50% is placed in the liquidity pool.",

      "GPX - 1% fees are charged when transacting GPX, of which 50% is allocated to the PAWN, our intelligent trading algorithm and the remaining 50% is allocated to the liquidity pool.",
    ],
  },
  {
    question:
      "What happens to my GPO tokens when I exercise my option and purchase GPX?",
    answer: [
      "The GPO tokens are burnt and removed from circulation, reducing the overall supply.",
    ],
  },
  {
    question:
      "Why should I exercise my GPO option when I could make more money just holding the option?",
    answer: ["Unlike GPX, GPO is not backed by physical gold."],
  },
  {
    question: "What is the expiry date of the GPO options?",
    answer: ["Unlike traditional options, GPO tokens do not expire."],
  },
  {
    question: "What is the process of exercising GPO and minting GPX tokens?",
    answer: [
      "GoldPesa Option (GPO) holders will have the ability to exercise their option and purchase newly minted GPX tokens by going to <a href='https://app.goldpesa.com/'>app.goldpesa.com</a> and swapping GPO along with USDC in exchange for GPX.",
    ],
  },
  {
    question: "Is GoldPesa regulated by any financial authority?",
    answer: [
      "GoldPesa is currently not regulated by any financial authority but expects to be in the near future.",
    ],
  },
];
