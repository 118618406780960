import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
import Loader from "components/Loader";
import { teamConfig } from "configs/teamConfig";
import FounderImage from "assets/images/founder-image.png";
import LinkedInIcon from "assets/images/team/linkedin-icon-blue.png";
import TeamCard from "components/TeamCard";
import { useTranslation } from "react-i18next";

const Team = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, [2500]);
  }, []);

  return (
    <div className="container">
      <Loader isLoading={isLoading} />

      <div className={styles.team}>
        <section className={styles.team__section}>
          <div className={styles.team__bottom}>
            <div className={styles.team__grid}>
              <div className={styles.team__grid__left}>
                <div className={styles.team__image}>
                  <figure>
                    <img src={FounderImage} alt="Shamik Raja" />
                  </figure>
                </div>
              </div>

              <div className={styles.team__grid__right}>
                <div className={styles.team__title}>
                  <div>
                    <h1 className="heading-2 fullWidth">{t("Shamik Raja")}</h1>
                    <p className={styles.team__text}>{t("Founder - CEO")}</p>
                  </div>

                  <a
                    href="https://www.linkedin.com/in/shamikraja/"
                    target="_blank"
                    rel="noreferrer"
                    className={styles.team__linkedin}
                  >
                    <figure>
                      <img src={LinkedInIcon} alt="Shamik's Linkedin" />
                    </figure>
                  </a>
                </div>

                <div>
                  <p className={styles.team__text}>
                  {t("GoldPesa is the brainchild of developer and computer engineer Shamik Raja, who has extensive experience in digital signal processing, programming, commodities, and quantitative science.")}
                  </p>
                  <p className={styles.team__text}>
                  {t("Between 2001 and 2006, Raja earned a degree in Electrical & Computer Engineering from the University of British Columbia in Canada. Over the years, he has focused on research involving the application of principals in digital signal processing to create an automated commodity-buying system that uses algorithmic calculations to navigate complex international commodity and currency markets.")}
                  </p>
                  <p className={styles.team__text}>
                  {t("GoldPesa is an amalgamation of his 8 years of’ experience in the precious metals sector combined with over 20 years in quantitative science and financial engineering.")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className={styles.team__section}>
          <div className={styles.team__top}>
            <div className={styles.videoPlayer}>
              <div className={styles.videoPlayer__wrapper}>
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube-nocookie.com/embed/OikFAOriy6k"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </div>
        </section>

        <section className={styles.team__section}>
          <div className={styles.ourTeam}>
            <h1 className="heading-2 center fullWidth">{t("Our Team")}</h1>
            <div className={styles.ourTeam__grid}>
              {teamConfig &&
                teamConfig.length &&
                teamConfig.map((member, index) => {
                  return (
                    <TeamCard
                      key={index}
                      name={t(member.name || "")}
                      specializations={
                        Array.isArray(member.specializations)
                          ? member.specializations.map(specialization =>
                              t(specialization || "")
                            )
                          : ""
                      }
                      linkedin={member.linkedin || ""}
                      avatar={member.avatar || ""}
                    />
                  );
                })}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Team;
