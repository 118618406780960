export const roadmapConfig = [
  {
    startDate: new Date("2019-12-01"),
    endDate: new Date("2019-12-31"),
    name: "Dec 2019",
    text: "GoldPesa was just a dream...",
  },
  {
    startDate: new Date("2021-10-01"),
    endDate: new Date("2021-10-31"),
    name: "Oct 2021",
    text: "5 million GPO sold",
  },
  {
    startDate: new Date("2021-10-01"),
    endDate: new Date("2021-10-31"),
    name: "Oct 2021",
    text: "GPO listed on GoldPesa DEX powered by Uniswap",
  },
  {
    startDate: new Date("2022-10-01"),
    endDate: new Date("2022-10-31"),
    name: "Oct 2022",
    text: "GoldPesa featured on Netflix",
  },
  {
    startDate: new Date("2023-01-01"),
    endDate: new Date("2023-01-31"),
    name: "Jan 2023",
    text: "13 million GPO sold",
  },
  {
    startDate: new Date("2023-05-01"),
    endDate: new Date("2023-05-31"),
    name: "May 2023",
    text: "GoldPesa on Polygon",
  },
  {
    startDate: new Date("2023-05-01"),
    endDate: new Date("2023-05-31"),
    name: "May 2023",
    text: "GoldPesa Liquidity Protocol",
  },
  {
    startDate: new Date("2023-10-01"),
    endDate: new Date("2023-10-31"),
    name: "Oct 2023",
    text: "GoldPesa Academy",
  },
  {
    startDate: new Date("2023-12-01"),
    endDate: new Date("2023-12-31"),
    name: "Dec 2023",
    text: "GoldPesa Mines",
  },
  {
    startDate: new Date("2025-01-01"),
    endDate: new Date("2025-01-31"),
    name: "Q1 2025",
    text: "GPX Launch",
  },
];
