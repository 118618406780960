export const mediaAndPressConfig = [
  {
    logo: "assets/images/media-logo/logo-1.png",
    date: "Oct 27, 2022",
    title:
      "GoldPesa on Netflix Featured on “Dubai Bling,” Trending #1 on Netflix",
    thumbnail: "assets/images/media-thumbnail/thumbnail-1.png",
    link: "/",
  },
  {
    logo: "assets/images/media-logo/logo-2.png",
    date: "Apr 15, 2022",
    title: "South Florida Business Journal - Cryptocurrency Entrepreneurs.",
    thumbnail: "assets/images/media-thumbnail/thumbnail-2.png",
    link: "https://www.gciwcorp.com/media/#dflip-df_218755/1/",
  },
  {
    logo: "assets/images/media-logo/logo-3.png",
    date: "Mar 31, 2022",
    title: "Crypto Expo - GoldPesa CEO Shamik Raja's Keynote Speech",
    thumbnail: "assets/images/media-thumbnail/thumbnail-3.png",
    link: "https://youtu.be/vkdK_4wdfl0",
  },
  {
    logo: "assets/images/media-logo/logo-4.png",
    date: "Mar 16, 2022",
    title: "Crypto TV Interview with Shamik Raja at the  Dubai Crypto Expo",
    thumbnail: "assets/images/media-thumbnail/thumbnail-4.png",
    link: "https://youtu.be/n4raziLnf0M",
  },
  {
    logo: "assets/images/media-logo/logo-5.png",
    date: "Feb 15, 2022",
    title: "United Nations - Shamik Raja Interview at The Royal Summit",
    thumbnail: "assets/images/media-thumbnail/thumbnail-5.png",
    link: "https://youtu.be/ZW6urVk2Sxs",
  },
  {
    logo: "assets/images/media-logo/logo-6.png",
    date: "Feb 01, 2022 ",
    title: "Shamik Raja Interview with Bloomberg Correspondent",
    thumbnail: "assets/images/media-thumbnail/thumbnail-6.png",
    link: "https://youtu.be/JOm8gqu03Rw",
  },
  {
    logo: "assets/images/media-logo/logo-7.png",
    date: "Jan 26, 2022 ",
    title: "GoldPesa a unique gold-backed income generating digital currency",
    thumbnail: "assets/images/media-thumbnail/thumbnail-7.png",
    link: "https://www.newsofbahrain.com/business/78241.html",
  },
  {
    logo: "assets/images/media-logo/logo-8.png",
    date: "Jan 21, 2022",
    title: "GoldPesa and its gold-backed, Bitcoin inspired token",
    thumbnail: "assets/images/media-thumbnail/thumbnail-8.png",
    link: "https://capital.com/goldpesa-and-its-gold-backed-bitcoin-inspired-token",
  },
];
