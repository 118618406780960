import React, { useEffect, useState, useRef } from "react";
import styles from "./index.module.scss";
import Loader from "components/Loader";
import Accordion from "components/Accordion";
import { faqConfig } from "configs/faqConfig";
import { useTranslation } from "react-i18next";

const FAQ = () => {
  const { t } = useTranslation();
  const accordionRef = useRef([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2500);
  }, []);

  const translatedFaqConfig = faqConfig.map((item) => ({
    ...item,
    translatedQuestion: t(item.question),
    // translatedAnswer: t(item.answer, { returnObjects: true }),
    translatedAnswer: item.answer.map((ans)=> {
      return t(ans, { returnObjects: true });
    })
  }
  
  ));

  return (
    <div className="container">
      <Loader isLoading={isLoading} />

      <div className={styles.faq}>
        <section className={styles.faq__section}>
          <div className={styles.faq__top}>
            <h1 className="heading-2 center">{t("FAQ")}</h1>

            <div className={styles.faq__layout}>
              <div className={styles.faq__accordionWrapper}>
                {translatedFaqConfig.map((item, index) => (
                  <Accordion
                    key={index}
                    index={index}
                    innerRef={(ref) => (accordionRef.current[index] = ref)}
                    parentRef={accordionRef}
                    title={item.translatedQuestion}
                    content={item.translatedAnswer}
                  />
                ))}
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default FAQ;
