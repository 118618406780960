export const homeAnimationTextConfig = [
  {
    from: 1,
    to: 13,
    text: "The GoldPesa ecosystem involves 3 main components",
    position: "topMidCenter",
  },
  {
    from: 14,
    to: 45,
    text: "Let's start with the GoldPesa token, also known as GPX.",
    position: "topRight",
  },
  {
    from: 46,
    to: 100,
    text: "The best way to understand GPX is to simply follow the money!",
    position: "bottomLeft",
  },
  {
    from: 101,
    to: 146,
    text: "Every GPX token is backed by 1 gram of physical gold.",
    position: "topMid",
  },
  {
    from: 147,
    to: 203,
    text: "There is a 1% fee anytime GPX is bought, sold or transferred",
    position: "topLeft",
  },
  {
    from: 204,
    to: 285,
    text: "50% of the Fees are allocated to the PAWN, our proprietary intelligent trading algorithm.",
    position: "bottomMid",
  },
  {
    from: 286,
    to: 348,
    text: "The PAWN generates a profit by leveraging the capital and automatically placing trades in the forex market.",
    position: "topRight",
  },
  {
    from: 362,
    to: 446,
    text: "The PAWN's performance is broadcasted in real-time which not only creates transparency but also volatility among speculators in anticipation of the buyback.",
    position: "topLeft",
  },
  {
    from: 453,
    to: 530,
    text: "The volatility creates exponentially growing fees which results in an exponentially growing capital base for the PAWN.",
    position: "topLeft",
  },
  {
    from: 531,
    to: 624,
    text: "Profits generated by the PAWN are used to buyback and burn GPX tokens, which rewards token holders by increasing demand and reducing supply at the same time, driving the price higher.",
    position: "topLeft",
  },
  {
    from: 625,
    to: 737,
    text: "This cycle continues creating tremendous value for token holders. As a result, the price of GPX floats away from the spot price of gold and trades at a premium.",
    position: "topLeft",
  },
  {
    from: 738,
    to: 789,
    text: "The PAWN buy back and burn function effectively creates earnings for token holders.",
    position: "topRight",
  },
  {
    from: 790,
    to: 890,
    text: "An asset that generates earnings has the potential to trade at a multiple of its earnings based on a P/E ratio which we traditionally see in the stock market.",
    position: "topRight",
  },
  {
    from: 920,
    to: 976,
    text: "What is GPO?",
    position: "topMid",
  },
  {
    from: 977,
    to: 1168,
    text: "For example, if GPX is trading at $110 USD and the spot gold price for 1 gram is $50 USD then the value of GPO would equal $110 minus $50 which equals $60.",
    position: "topMid",
  },
  {
    from: 1169,
    to: 1284,
    text: "GPO is therefore a call option which has the potential of a 100X token but is built on the backbone of intrinsic value rather than sheer speculation.",
    position: "topRight",
  },
  {
    from: 1285,
    to: 1368,
    text: "1 GPO gives you the option to purchase 1 GPX at spot gold price + 1%.",
    position: "bottomMid",
  },
  {
    from: 1382,
    to: 1448,
    text: "GPO token holders can choose to exercise their option or simply trade their GPO like any other cryptocurrency.",
    position: "topMid",
  },
  {
    from: 1490,
    to: 1554,
    text: "As a derivative of GPX, GPO will trade independently with a value correlated to the premium of GPX",
    position: "bottomMid",
  },
];
