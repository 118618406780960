import React, { useRef, useState, useEffect } from "react";
import styles from "./index.module.scss";
import CardStyle1 from "components/Cards/Style1";

/* Images & Icons */
import GPOImage from "assets/images/gpo-icon.png";
import PawnImage from "assets/images/pawn-icon.png";
import GPXImage from "assets/images/gpx-icon.png";
import { ReactComponent as ArrowDownCircleIcon } from "assets/images/icons/arrow-down-circle.svg";
import Loader from "components/Loader";
import { useTranslation } from "react-i18next";
import InfiniteLoopBrandSlider from "components/InfiniteLoopBrandSlider";
import { useQuery } from "react-query";

const Home = () => {
  const { t } = useTranslation();
  const { isLoading: load, error, data } = useQuery('priceData', () =>
    fetch('https://gpw-api.goldpesa.com/price').then(res =>
      res.json()
    )
  )

  const pricePretty =
    !(load || error)
      ? new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(data.price)
      : "...";
  const volumePretty =
      (load || error)
      ? "..."
      : new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(data.vol24hr);
  const videoSectionRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  const scrollToAnimationSection = () => {
    const headerHeight = document.getElementById("header").clientHeight;
    const y =
      videoSectionRef.current.getBoundingClientRect().top +
      window.pageYOffset -
      headerHeight;

    window.scrollTo({ top: y, behavior: "smooth" });
  };

  const launchAppFunc = () => {
    window.location.href = "https://app.goldpesa.com/";
  };

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, [2500]);
  }, []);

  return (
    
    <div className="container">
      <Loader isLoading={isLoading} />
   
      <section>
        <div className={styles.heroSection}>
          <div
            className={`
            ${styles.heroSection__heading}
            ${styles["heroSection__heading--1"]}
            `}
          >
            <h1 className="heading-1 small center">
            {t("An advanced form of money")}
            </h1>
          </div>

          <div className={styles.heroSection__cardsGrid}>
            <CardStyle1
              image={GPOImage}
              title="GPO"
              subTitle={<div style={{marginTop: '15px'}}>0x0308a3a9c433256aD7eF24dBEF9c49C8cb01300A</div>}
              attributes={[
                { name: t('Price'), value: pricePretty }, 
                { name: t('24HR Volume'), value: volumePretty },
              ]}
              showCta={true}
              ctaText={t("Buy Now")}
              ctaFunc={launchAppFunc}
            />
            <CardStyle1
              image={PawnImage}
              title="PAWN"
              subTitle={<div style={{marginTop: '20px'}}></div>}
              attributes={[
                { name: "% AAR", value: "38.0%" },
                { name: "% YTD", value: "0.00%" },
              ]}
              showCta={false} lastUpdated="01/01/2024 00:00 GMT"
            />
            <CardStyle1
              image={GPXImage}
              title="GPX"
              subTitle={<div style={{marginTop: '15px'}}>{t("Coming soon")}</div>}
              attributes={[
                { name: t('Price'), value: t('TBA') },
                { name: t('24HR Volume'), value: t('TBA') },
              ]}
              showCta={true}
              ctaText={t("Q1 2025")}
              ctaFunc={() => {}}
            />
          </div>

          <section className={styles.mediaAndPress__section} >
            <div className={styles.mediaAndPress__brandsSlider} >
              <InfiniteLoopBrandSlider />
            </div>
          </section>

          <div className={styles.heroSection__sectionScroller}>
            <span
              className={`${styles.heroSection__sectionScroller__icon} myLinkForCursor`}
              onClick={scrollToAnimationSection}
            >
              <ArrowDownCircleIcon />
            </span>
          </div>
        </div>
      </section>
      

      <section ref={videoSectionRef} className={styles.videoSection}>
        <div className={styles.videoSection__video}>
          <div className={styles.videoSection__video__wrapper}>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube-nocookie.com/embed/9XlmywJQtjA"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
