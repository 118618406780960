/* -------------------------------------------------------- */
/* ---- This Function Deep Clone Given Object or Array ---- */
/* -------------------------------------------------------- */

export const customCloneDeep = (inObject) => {
  let outObject, value, key;

  if (typeof inObject !== "object" || inObject === null) {
    return inObject; // Return the value if inObject is not an object
  }

  // Create an array or object to hold the values
  outObject = Array.isArray(inObject) ? [] : {};

  for (key in inObject) {
    value = inObject[key];

    // Recursively (deep) copy for nested objects, including arrays
    outObject[key] = customCloneDeep(value);
  }

  return outObject;
};

/* -------------------------------------------------------- */
/* --------------- Custom Debounce Function --------------- */
/* -------------------------------------------------------- */

export const debounce = (func, timeout = 150) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
};

/* -------------------------------------------------------- */
/* ---------- This Function Detects Network Speed --------- */
/* -------------------------------------------------------- */
const calculateSpeed = (imageSize, startTime, endTime) => {
  let timeDuration = (endTime - startTime) / 1000;
  let loadedBits = imageSize * 8;
  /* Converts a number into string using toFixed(2) rounding to 2 */
  let speedInBps = (loadedBits / timeDuration).toFixed(2);
  let speedInKbps = (speedInBps / 1024).toFixed(2);
  let speedInMbps = (speedInKbps / 1024).toFixed(2);

  return parseFloat(speedInMbps || 0);
};

export const getConnectionSpeed = () => {
  return new Promise((resolve) => {
    try {
      let startTime, endTime;
      let imageSize = "";
      let image = new Image();

      //Gets random image from unsplash.com
      let imageLink = "https://source.unsplash.com/random?topics=nature";

      //When image loads
      image.onload = () => {
        endTime = new Date().getTime();

        //Get image size
        fetch(imageLink).then((response) => {
          imageSize = response.headers.get("content-length");
          const mbps = calculateSpeed(imageSize, startTime, endTime);
          resolve(mbps);
        });
      };

      image.src = imageLink;
      startTime = new Date().getTime();
    } catch (error) {
      resolve(0);
    }
  });
};
