import React from "react";
import styles from "./index.module.scss";

const CardStyle1 = ({
  image = "",
  title = "",
  subTitle = undefined,
  attributes = [],
  showCta = false,
  ctaText = "",
  lastUpdated,
  ctaFunc = () => { },
}) => {
  return (
    <div className={styles.card}>
      <div className={styles.card__wrapper} style={{height: '100%'}}>
        <div className={styles.card__top}>
          <div className={styles.card__image}>
            <figure>
              <img src={image} alt={title || ""} />
            </figure>
          </div>

          <div className={styles.card__title}>
            <h2>{title}</h2>
          {subTitle &&
            <div className={styles.card__subTitle}>
              {subTitle}
            </div>}
          </div>
        </div>

        <div className={styles.card__middle}>
          <div className={styles.card__attributes}>
            {attributes.length &&
              attributes.map((attribute, index) => {
                return (
                  <div key={index} className={styles.card__attributes__item}>
                    <span className={styles.card__attributes__name}>
                      {attribute.name || ""}
                    </span>
                    <span className={styles.card__attributes__value}>
                      {attribute.value || ""}
                    </span>
                  </div>
                );
              })}
          </div>
        </div>

        <div
          className={styles.card__bottom}
          style={{ display: ctaText ? "block" : "none" }}
        >
          {showCta && (
            <button type="button" onClick={ctaFunc}>
              {ctaText}
            </button>
          )}
        </div>

        {lastUpdated && (
          <div className={styles.card__lastUpdated}>
            <p>Last Updated</p>
            <p>{lastUpdated}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default CardStyle1;
