import React, { useEffect, Fragment } from "react";
import styles from "./index.module.scss";
import Lottie from "react-lottie";
import LoaderAnimation from "assets/lottie/Loader.json";

const Loader = ({ isLoading }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: LoaderAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  document.getElementByTagName;

  useEffect(() => {
    const body = document?.getElementsByTagName("body")[0];
    if (isLoading) {
      body.style.overflow = "hidden";
    } else {
      body.style.overflow = "auto";
    }
  }, [isLoading]);

  return (
    <Fragment>
      {isLoading && (
        <div className={styles.loader}>
          <div className={`${styles.loader__wrapper} container`}>
            <div className={styles.loader__animation}>
              <Lottie options={defaultOptions} isClickToPauseDisabled={true} />
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default Loader;
