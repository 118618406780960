import React, { useEffect, useState, } from "react";
import { useTranslation } from "react-i18next";
import styles from "./index.module.scss";
import InfiniteLoopBrandSlider from "components/InfiniteLoopBrandSlider";
import MediaCardsSlider from "components/MediaCardsSlider";
import Loader from "components/Loader";

const MediaAndPress = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 3500);
  }, []);

  return (
    <div className="container">
      <Loader isLoading={isLoading} />

      <div className={styles.mediaAndPress}>
        <section className={styles.mediaAndPress__section}>
          <div className={styles.mediaAndPress__top}>
            <h1 className="heading-2 center">{t("mediaPress.title")}</h1>
          </div>
        </section>

        <section className={styles.mediaAndPress__section}>
          <div className={styles.mediaAndPress__brandsSlider}>
            <InfiniteLoopBrandSlider />
          </div>
        </section>

        <section className={styles.mediaAndPress__section}>
          <div className={styles.mediaAndPress__bottom}>
            <div className={styles.mediaAndPress__newsSlider}>
              <MediaCardsSlider />
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default MediaAndPress;
