import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";

// Import required modules
import { Autoplay } from "swiper";

const InfiniteLoopBrandSlider = () => {
  const [images, setImages] = useState([]);

  const swiperParams = {
    slidesPerView: 7,
    spaceBetween: 100,
    allowTouchMove: false,
    loop: true,
    autoplay: {
      delay: 0,
      disableOnInteraction: false,
      waitForTransition: false,
    },
    speed: 3800,
    modules: [Autoplay],
    // Responsive breakpoints
    breakpoints: {
      // when window width is >= 320px
      320: {
        spaceBetween: 35,
      },
      // when window width is >= 1440px
      1440: {
        spaceBetween: 50,
      },
      // when window width is >= 1600px
      1600: {
        spaceBetween: 100,
      },
    },
  };

  useEffect(() => {
    const fetchImages = async () => {
      const myArray = [];

      for (let duplicates = 1; duplicates <= 5; duplicates++) {
        for (let i = 1; i <= 14; i++) {
          const image = await import(`assets/images/brands/${i}.png`);
          myArray.push(image.default);
        }
      }

      setImages(myArray);
    };

    fetchImages();
  }, []);

  return (
    <div className={styles.InfiniteLoopBrandSlider}>
      <Swiper {...swiperParams} className={styles.mySwiper} dir="ltr">
        {images.length > 0 &&
          images.map((image, index) => (
            <SwiperSlide key={index} className={styles.mySwiperSlide}>
              <div className={styles.InfiniteLoopBrandSlider__image}>
                <img src={image} alt={`brand-${index + 1}`} />
              </div>
            </SwiperSlide>
          ))}
      </Swiper>
    </div>
  );
};

export default InfiniteLoopBrandSlider;
