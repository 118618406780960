import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
import Loader from "components/Loader";
import HeroImage from "assets/images/xenii/hero.png";
import Image3 from "assets/images/xenii/3.png";

const Xenii2 = () => {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, [0]);
  }, []);

  return (
    <div className="container">
      <Loader isLoading={isLoading} />

      <div className={styles.xenii}>
        <div className={styles.xenii__heroSection}>
          <div className={styles.heroSection__section}>
            <div className={styles.heroSection__top}>
              <div className={styles.heroSection__heading}>
                <h2>Xenii 2.0</h2>
              </div>
            </div>

            <div className={styles.heroSection__bottom}>
              <div className={styles.heroSection__image__wrapper}>
                <figure className={styles.heroSection__image}>
                  <img src={HeroImage} alt="Xenii 2.0" />
                </figure>
              </div>
            </div>

            <div className={styles.heroSection__text}>
              <p>
                XENII is a private membership community designed to help connect
                global brands to the Hollywood tastemaker on a consistent basis
                in this digital crypto age.
              </p>
              <p>
                XENII was originally launched by founder Michael Sutton in 2005.
                XENII brought the world's elite together with A-list celebrities
                in a private, exclusive and unforgettable after party
                experience.
              </p>
              <p>
                Hollywood had never seen anything like XENII before and we are
                now bringing this elite unicorn experience back for the next
                generation.
              </p>
              <p>
                XENII events will be held at sound stages around Los Angeles on
                a quarterly basis from Midnight until 5AM.
              </p>
            </div>
          </div>
        </div>

        <div className={styles.xenii__gallery}>
          <div className={styles.gallery}>
            <div className={styles.gallery__item}>
              <figure>
                <img src={Image3} alt="Xenii" />
              </figure>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Xenii2;
