export const teamConfig = [
    {
        name: "Heroies Havewalla",
        specializations: ["Operations", "Precious Metals", "Business Development"],
        linkedin: "https://www.linkedin.com/in/heroies-havewalla-b2a70256/",
        avatar: "assets/images/team/heroies-havewalla.png"
    },
    {
        name: "Alexandre De Angelis",
        specializations: ["Head of Engineering", "Quantitative Analyst", "Software Engineer"],
        linkedin: "https://www.linkedin.com/in/alexandre-de-angelis-46a287a3/",
        avatar: "assets/images/team/alexandre-de-angelis.png"
    },
    {
        name: "Sina Tootoonian",
        specializations: ["PhD Neuroscience", "Electrical Engineer", "Artificial Intelligence"],
        linkedin: "https://www.linkedin.com/in/sina-tootoonian-99668838/?originalSubdomain=uk",
        avatar: "assets/images/team/sina-tootoonian.png"
    },
    {
        name: "Wil Yeung",
        specializations: ["Goldman Sachs", "Chemical Engineer", "Equity Derivatives"],
        linkedin: "https://www.linkedin.com/in/wilyeung/",
        avatar: "assets/images/team/wil-yeung.png"
    },
    {
        name: "Zahid Pirani",
        specializations: ["Operating Officer", "Design Engineer", "Marketing Expert"],
        linkedin: "https://www.linkedin.com/in/zahid-pirani-17985ab5/",
        avatar: "assets/images/team/zahid-pirani.png"
    },
    {
        name: "Abhimanyu Malhotra",
        specializations: ["Blockchain Technology", "Digital Assets", "Decentralised Finance"],
        linkedin: "https://www.linkedin.com/in/abhimalhotra",
        avatar: "assets/images/team/abhimanyu-malhotra.png"
    },
    {
        name: "Yadav Jani",
        specializations: ["Financial Advisor", "Entrepreneur", "Investment Strategy"],
        linkedin: "https://www.linkedin.com/in/yadav-jani-7320a9a6/",
        avatar: "assets/images/team/yadav-jani.png"
    },
    {
        name: "Michael Sutton",
        specializations: ["Brand Ambassador", "Celebrity Management", "Serial Entrepreneur"],
        linkedin: "https://www.linkedin.com/in/sut20/",
        avatar: "assets/images/team/michael-sutton.png"
    },
]