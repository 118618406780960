import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
import { ReactComponent as ChevronDownIcon } from "assets/images/icons/chevron-down-2.svg";

const Accordion = ({ title, content, index, innerRef, parentRef }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleAccordion = () => {
    setIsExpanded((prevState) => !prevState);
  };

  useEffect(() => {
    const handleClickOutside = ({ target }) => {
      try {
        if (
          parentRef?.current[index] &&
          !parentRef.current[index].contains(target)
        ) {
          setIsExpanded(false);
        }
      } catch (error) {
        console.log(error);
      }
    };

    document && document?.addEventListener("click", handleClickOutside);

    return () => {
      document && document?.removeEventListener("click", handleClickOutside);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      ref={innerRef}
      className={`${styles.accordion} ${
        isExpanded ? styles["accordion--expanded"] : ""
      }`}
    >
      <div className={styles.accordion__top}>
        <h2 className={styles.accordion__title}>{title}</h2>

        <span className={styles.accordion__icon} onClick={toggleAccordion}>
          <ChevronDownIcon />
        </span>
      </div>

      {/* <div className={styles.accordion__bottom} dangerouslySetInnerHTML={{ __html: content }}>        
      </div> */}
      <div className={styles.accordion__bottom}>
        {content.map((data, index) => (
          <p  key={index} dangerouslySetInnerHTML={{ __html: data }}></p>
        ))}
      </div>
    </div>
  );
};

export default Accordion;
