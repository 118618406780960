import React, { useEffect, useState } from "react";
import styles from "./Layout.module.scss";
import Header from "components/Header";
import Footer from "components/Footer";
import { useLocation } from "react-router-dom";

const Layout = ({ children }) => {
  const location = useLocation();
  const [autoHeight, setAutoHeight] = useState(false);

  useEffect(() => {
    if (location?.pathname?.includes("stake-2-win")) {
      setAutoHeight(true);
    } else {
      setAutoHeight(false);
    }
  }, [location]);

  return (
    <div className={`${styles.layout} container`}>
      <div className={styles.layout__wrapper}>
        <div className={styles.layout__banner}>WARNING: ONLY SWAP FROM <a href="https://app.goldpesa.com">APP.GOLDPESA.COM</a></div>
        <Header />
        <div
          className={`${styles.layout__mainContent} ${
            autoHeight ? styles["layout__mainContent--autoHeight"] : ""
          }`}
        >
          {children}
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Layout;
